<template>
  <div class="content">
    <div class="content_wrap">
      <div class="content_crumbs">
        当前位置：<span style="cursor: pointer" @click="goIndex">首页 > </span>
        <span style="cursor: pointer" @click="goHistory">信息 > </span>
        <span style="cursor: pointer">正文</span>
      </div>

      <div class="content_content">
        <div class="content_content_title">
          {{ contentTitle }}
        </div>

        <div class="content_content_crumbs" :style="fixedPosition">
          <!-- <div
            class="content_content_crumbs_back"
            @click="goHistory"
            style="cursor: pointer"
          >
            <img :src="contentCrumbs.backIcon" />
            返回列表页
          </div> -->
          <div class="content_content_crumbs_time">
            <img :src="contentCrumbs.timeIcon" />
            发布时间：{{ publishTime }}
          </div>
        </div>

        <!-- <div class="contetn_content_generalization">
          <div class="contetn_content_generalization_title">项目概况</div>
          <div class="contetn_content_generalization_content">
            {{ contentTitle }}
          </div>
        </div> -->

        <!-- 
        <div class="content_content_RelevantTime">
          <div class="content_content_RelevantTime_item">
            公告发布时间：<span>{{ createTime }}</span>
          </div>
          <div
            class="content_content_RelevantTime_item"
            style="text-align: center"
          >
            招标文件获取开始时间：<span>{{ startTime }}</span>
          </div>
          <div
            class="content_content_RelevantTime_item"
            style="text-align: right"
          >
            招标文件获取截取时间：<span>{{ endTime }}</span>
          </div>
        </div> -->

        <div class="content_content_item_wrap">
          <!-- <div class="content_content_item">
            <p v-html="showHtml(ActiveItem.description)"></p>
          </div>
          <hr /> -->
          <div class="content_content_item">
            <p v-html="showHtml(content)"></p>
          </div>
        </div>

        <!-- <div class="content_content_fileInfo" v-if="haveFile">
          <div class="content_content_fileInfo_title">文件信息</div>
          <div class="content_content_fileInfo_content">
            <img :src="contentIcon" />
            <div class="content_content_fileInfo_contetn_fileName">
              海门市职教中心校园运动操场改造提升工程 【招标文件正文】SignPDF.pdf
            </div>
          </div>
        </div> -->

        <div
          class="content_content_fileInfo"
          v-for="fileItem in activeAttachList"
          :key="fileItem"
          @click="toHref(fileItem)"
        >
          <div class="content_content_fileInfo_title">
            {{ fileItem.originalName }}
          </div>
          <div class="content_content_fileInfo_content">
            <img :src="contentIcon" />
            <div class="content_content_fileInfo_contetn_fileName">
              {{ fileItem.originalName }}
            </div>
          </div>
        </div>

        <div class="content_page_turner" v-if="haveTurner">
          <div class="content_page_turner_next">
            <span>下一篇</span
            >：2022年中央支持学前教育发展资金预算校舍维修改造项目招标公告
          </div>
          <div class="content_page_turner_before">
            <span>上一篇</span
            >：2022年中央支持学前教育发展资金预算校舍维修改造项目招标公告
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  onMounted,
  onBeforeMount,
  watchEffect,
  ref,
  onUnmounted,
} from "vue";
import imgData from "@/data";
import { stateStore, mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import allUrl from "@/config/url.js";

export default {
  props: ["haveFile", "haveTurner"],
  setup() {
    const showHtml = (str) => {
      return (
        str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#39;/g, "'")
          // .replace(/.*?\}/g, "")
          .replace(/&amp;nbsp;/g, "\u3000")
      );
    };
    onBeforeMount(() => {
      window.addEventListener("scroll", () => {
        stateStore().setscrollX(document.documentElement.scrollTop);
      });
      const route = useRoute();
      stateStore().selectArticleById(route.query.id);
      // if (route.query.type) {
      //   document.title = route.query.type;
      // }
      // .then((res) => {
      //   document.title = res;
      // });
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", () => {
        console.log("remove");
      });
    });
    const fixedPosition = computed(() =>
      storeToRefs(stateStore()).scrollX.value > 400
        ? "position: fixed;top:0;right: 16%;width: 68%;"
        : ""
    );
    const router = useRouter();
    const storeOfstate = stateStore();
    const content = computed(() => {
      return storeToRefs(storeOfstate).activeHtml;
    });
    // const content = computed(() => localStorage.getItem("activeHtml"));
    const contentTitle = computed(() => {
      return storeToRefs(storeOfstate).activeTitle;
    });
    const publishTime = computed(
      () => storeToRefs(storeOfstate).activePublishTime
    );
    // const contentTitle = computed(() => localStorage.getItem("activeTitle"));
    // const publishTime = computed(() =>
    //   localStorage.getItem("activePublishTime")
    // );
    const ActiveItem = computed(() => storeToRefs(storeOfstate).activeItem);
    // const ActiveItem = computed(() =>
    //   JSON.parse(localStorage.getItem("activeItem"))
    // );
    const activeAttachList = computed(
      () => storeToRefs(storeOfstate).activeAttachList
    );
    const goHistory = () => {
      router.go(-1);
    };
    const toHref = (item) => {
      // window.open(location.origin + "/" + url);
      const a = document.createElement("a");
      a.href = location.origin + "/" + item.name;
      a.download = item.originalName;
      a.click();
    };
    const goToJoin = () => {
      window.open(allUrl.ServicePlatform);
    };
    const goIndex = () => {
      mainStore().setactivePageUrl("/index");
      router.push({
        path: "/index",
      });
    };
    const data = reactive({
      ...imgData.BiddingInfoImg,
      content,
      contentTitle,
      publishTime,
      ActiveItem,
      activeAttachList,
    });

    return {
      ...data,
      goHistory,
      goToJoin,
      goIndex,
      fixedPosition,
      showHtml,
      toHref,
    };
  },
};
</script>

<style scoped>
@import url("./Content.css");
</style>

